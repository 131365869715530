import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthenticator } from '@aws-amplify/ui-react';

const AuthOpener = styled.button`
    color: white;
    padding: 8px 23px;
    background-color: #37ab31;
    display: inline-block;
    margin-bottom: 0;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;   
    font-weight: 700;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    touch-action: manipulation;
    border-radius: 8px;
    border: none;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s;
    &:hover {
        color:white;
        box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}
`

function ActivitiesOverlay(props) {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);

    const scrolling = () => {
        if (props.modalRef.current && !props.isAuthenticatorOpen) {
          setTimeout(() => {
            props.modalRef.current.scrollIntoView({ behavior: "smooth" });
          }, 100);
        }
    };

    const openModal = async (e) => {
        if (authStatus !== 'authenticated') {
            props.openAuthenticatorModal();
            e.preventDefault();
            scrolling();
        }
    };

    useEffect(() => {
    }, [authStatus]);

    return (
        <div
            className="activities-login-overlay"
            style={{ opacity: !props.isAuthenticatorOpen && authStatus !== 'authenticated' ? '1' : '0' }}
        >
        {/* { (props.streamDone) &&  ( */}
            <AuthOpener
                onClick={openModal}
            >
                Sign up/Log in to view your itinerary - MyTripAssistant is free to use!
            </AuthOpener>
        {/* )} */}
        </div>
    )
}

export default ActivitiesOverlay;