import {Container, Col, Row} from "react-bootstrap";
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import { faInstagram, faTiktok, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
    return (
        <footer className="footer">
            <Container className="text-left">
                <Row className="mb-5 mt-3">
                    <Col

                        md={4}>
                        {/* <img
                            src={logo_no_text}
                            width="50"
                            alt="mybiros logo"
                        /> */}
                        <p className="mt-4">
                            <b>MyTripAssistant Ltd</b><br/>Juniper Dr<br/>SW18, London
                        </p>
                        <a href="mailto:info@mytripassistant.com">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span className="ms-2">info@mytripassistant.com</span>
                        </a>
                    </Col>
                    <Col

                        md={8}
                        className="links d-flex justify-content-center justify-content-sm-start justify-content-md-center">
                        <div className="mt-auto me-md-auto">
                            <h6 className="mt-md-0 mt-5">Links</h6>
                            <ul className="mb-0">
                                <li><a href="https://mytripassistant.com/">Create new Trip</a></li>
                                <li><a href="https://mytripassistant.com/">Privacy Policy</a></li>
                                <li><a href="https://mytripassistant.com/">Terms & Conditions</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <hr></hr>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={10}>
                        <p>MyTripAssistant Copyright © {new Date().getFullYear()}. All rights reserved. MyTripAssistant Ltd</p>
                    </Col>
                    <Col
                        className="text-md-end"
                        md={2}>
                        <a 
                            className="social"
                            href="https://www.tiktok.com/@mytripassist"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faTiktok}/>
                        </a>
                        <a 
                            className="social"
                            href="https://www.instagram.com/mytripassist/"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                        <a 
                            className="social"
                            href="https://www.linkedin.com/company/MyTripAssistant/"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faLinkedinIn}/>
                        </a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;