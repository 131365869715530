import React, { useEffect, useRef, useCallback } from 'react';

const TripWidget = ({ destination }) => {
    const tripWidgetRef = useRef(null);
    const tripScriptRef = useRef(false);

    const addScript = useCallback((containerRef, scriptRef, src, onLoadMessage) => {
        try {
            const container = containerRef.current;
            if (container && !scriptRef.current) {
                const existingScript = container.querySelector(`script[src="${src}"]`);
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.async = true;
                    script.src = src;
                    script.onload = () => {
                        console.log(onLoadMessage);
                        scriptRef.current = true;
                    };
                    container.appendChild(script);
                } else {
                    console.log(`${onLoadMessage} (already loaded)`);
                    scriptRef.current = true;
                }
            }
        } catch (error) {
            console.error("🚀 ~ addScript ~ error:", error);
        }
    }, []);

    useEffect(() => {
        const tripWidgetSrc = `https://tp.media/content?trs=288569&shmarker=509535&locale=en&curr=USD&powered_by=false&border_radius=0&plain=true&color_button=%2337ab31&color_button_text=%23ffffff&color_border=%2337ab31&promo_id=4132&campaign_id=121&default_origin=${encodeURIComponent(destination[0]?.address || '')}`;
        addScript(tripWidgetRef, tripScriptRef, tripWidgetSrc, 'trip.com script loaded 👍');

    }, [destination, addScript]);

    return <div id="trip-widget-container" ref={tripWidgetRef} style={{ margin: '30px 0' }}></div>;
};

export default TripWidget;
