import React, { useEffect, useRef, useCallback } from 'react';

const CarWidget = () => {
    const carWidgetRef = useRef(null);
    const carScriptRef = useRef(false);

    const addScript = useCallback((containerRef, scriptRef, src, onLoadMessage) => {
        try {
            const container = containerRef.current;
            if (container && !scriptRef.current) {
                const existingScript = container.querySelector(`script[src="${src}"]`);
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.async = true;
                    script.src = src;
                    script.onload = () => {
                        console.log(onLoadMessage);
                        scriptRef.current = true;
                    };
                    container.appendChild(script);
                } else {
                    console.log(`${onLoadMessage} (already loaded)`);
                    scriptRef.current = true;
                }
            }
        } catch (error) {
            console.error("🚀 ~ addScript ~ error:", error);
        }
    }, []);

    useEffect(() => {
        const rentCarSrc = "https://c172.travelpayouts.com/content?trs=288569&shmarker=509535&locale=en&powered_by=true&promo_id=4850";
        addScript(carWidgetRef, carScriptRef, rentCarSrc, 'car.com script loaded 👍');
    }, [addScript]);

    return <div id="car-widget-container" ref={carWidgetRef} style={{ margin: '30px 0' }}></div>;
};

export default CarWidget;
