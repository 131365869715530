import React, { useEffect, ChangeEventHandler, useState, useRef } from "react";
import "../../styles/override_datepicker.css";
import "react-day-picker/dist/style.css";
import FocusTrap from "focus-trap-react";
import { usePopper } from "react-popper";
import { format, isAfter, isBefore, isValid, parse } from "date-fns";
import { it } from "date-fns/locale";
import {
  // DateFormatter,
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from "react-day-picker";

function DateRangePicker(props) {
  const [selectedRange, setSelectedRange] = useState();
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const disabledDays = [{ from: new Date(1999, 1, 1), to: new Date() }];

  useEffect(() => { }, [isPopperOpen]);

  // const seasonEmoji: Record<string, string> = {
  //     winter: '⛄️',
  //     spring: '🌸',
  //     summer: '🌻',
  //     autumn: '🍂'
  //   };

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.setFromValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.setToValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };
  const popper = usePopper(popperRef.current, popperElement, {
    placement: "right",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };
  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen); // Inverte lo stato del popper
  };

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range);
    if (range?.from) {
      props.setFromValue(format(range.from, "y-MM-dd"));
    } else {
      props.setFromValue("");
    }
    if (range?.to) {
      props.setToValue(format(range.to, "y-MM-dd"));
    } else {
      props.setToValue("");
    }

    // Chiudi il popper se sia range.from che range.to sono valorizzati
    if (range?.from && range?.to) {
      closePopper();
    }
  };

  return (
    <div
      className="position-relative w-100 mt-1"
    >
      <div className="rdp-input-container from">
        <div className="rdp-labels">FROM</div>
        <input placeholder="From Date" value={props.fromValue} onChange={handleFromChange} onClick={handleButtonClick} className="rdp-input" />
      </div>
      <div className="rdp-input-container to">
        <div className="rdp-labels">TO</div>
        <input placeholder="To Date" value={props.toValue} onChange={handleToChange} onClick={handleButtonClick} className="rdp-input" />
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet mt-4"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeSelect}
              // max={7}
              showOutsideDays
              disabled={disabledDays}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}

export default DateRangePicker;
