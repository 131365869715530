import React, { useState, useEffect } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import MailchimpFormContainer from "./MailchimpFormContainer"

function AuthModal(props) {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { user } = useAuthenticator((context) => [context.user]);

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your Email',
        labelHidden: true,
      },
      password: {
        placeholder: 'Enter your Password',
        labelHidden: true,
      },
    },
    signUp: {
      password: {
        placeholder: 'Enter your Password',
        labelHidden: true,
      },
      confirm_password: {
        labelHidden: true,
        placeholder: 'Confirm your Password',
      },
      email: {
        labelHidden: true,
        placeholder: 'Enter your Email',
      },
      name: {
        labelHidden: true,
        placeholder: 'Enter your Name',
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password',
        labelHidden: true,
      },
    },
    resetPassword: {
      username: {
        placeholder: 'Enter your email',
        labelHidden: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code',
        labelHidden: true,
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please',
        labelHidden: true,
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        labelHidden: true,
        placeholder: 'Enter your Confirmation Code',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        labelHidden: true,
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  useEffect(() => {
    const checkUserAuthentication = async () => {
        if (authStatus === 'authenticated') {
          props.closeAuthenticatorModal();
        }
    };
    checkUserAuthentication(authStatus);
  }, [authStatus]);

  return (
    props.isAuthenticatorOpen && (
      <>
        <div
          onClick={props.closeAuthenticatorModal}
          className="auth-overlay"
        >

        </div>
        <div className="authenticator-modal">
          <Authenticator initialState="signUp" formFields={formFields}/>
        </div>
        <MailchimpFormContainer user={user}/>
      </>
    )
  );
};

export default AuthModal;