import React, { useState, useRef } from "react";
import "../../styles/override_datepicker.css";
import FocusTrap from "focus-trap-react";
import { usePopper } from "react-popper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, max } from "date-fns";

function TimePicker({time ,setTime , fromValue , toValue }) {
  const [isStartTimePopperOpen, setIsStartTimePopperOpen] = useState(false);
  const startTimePopperRef = useRef(null);
  const startTimeButtonRef = useRef(null);
  const [startTimePopperElement, setStartTimePopperElement] = useState(null);
  let isSameDate = fromValue && toValue ? fromValue === toValue : false;

  const startTimePopper = usePopper(startTimePopperRef.current, startTimePopperElement, {
    placement: "right",
  });

  const closeStartTimePopper = () => {
    setIsStartTimePopperOpen(false);
    startTimeButtonRef?.current?.focus();
  };

  const handleStartTimeButtonClick = () => {
    setIsStartTimePopperOpen(!isStartTimePopperOpen);
  };

  const handleStartTime = (time) => {
    setTime({startTime : time})
    setIsStartTimePopperOpen(false)
  }
  const handleEndTime = (time) => {
    setTime((prev) => ({
      ...prev,
      endTime : time
    }))
  }


  const minTime = isSameDate && time.startTime ? new Date(time.startTime.getTime() + 15 * 60000) : null;
  const maxTime = isSameDate && time.startTime ? new Date(time.startTime).setHours(23, 59, 59, 999) : null;



  // Handle time conversion for display
  const formatTime = (time) => (time ? format(time, "hh:mm aa") : "");

  return (
    <div className="position-relative w-100 mt-1">
      <div className="rdp-input-container from">
        <div className="rdp-labels">Start Time</div>
        <input
          placeholder="Select Start Time"
          value={formatTime(time.startTime)}
          readOnly
          onClick={handleStartTimeButtonClick}
          className="rdp-input"
        />
      </div>
      <div className="rdp-input-container to">
        <div className="rdp-labels">End Time</div>
        <DatePicker
          selected={time.endTime}
          onChange={handleEndTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeFormat="hh:mm aa"
          dateFormat="hh:mm aa"
          placeholderText="Select End time"
          // minTime={time.startTime ? new Date(time.startTime.getTime()).setMinutes(time.startTime.getMinutes() + 15) : null}
          // maxTime={time.startTime ? new Date(time.startTime.getTime()).setHours(23, 59) : null}
          // minTime={isSameDate ? new Date(time?.startTime?.getTime()).setMinutes(time?.startTime?.getMinutes() + 15) :null}
          // maxTime={isSameDate ? new Date(time?.startTime?.getTime()).setHours(23, 59) :  null}
          minTime={minTime}
          maxTime={maxTime}
          className="rdp-input"
        />
      </div>
      {isStartTimePopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closeStartTimePopper,
            fallbackFocus: startTimeButtonRef.current,
          }}
        >
          <div
            tabIndex={-1}
            style={startTimePopper.styles.popper}
            className="flightTimePicker"
            {...startTimePopper.attributes.popper}
            ref={setStartTimePopperElement}
            role="dialog"
            aria-label="TimePicker start time"
          >
            <DatePicker
              selected={time.startTime}
              onChange={handleStartTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeFormat="hh:mm aa"
              dateFormat="hh:mm aa"
              placeholderText="Select start time"
              inline
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}

export default TimePicker;
