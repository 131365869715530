import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWalking, faCar, faTrain } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";


function DistanceComponent(props) {
  const { currentAddress, previousAddress, distanceMode } = props;
  const [distance, setDistance] = useState(null);
  const [singleDistanceMode, setSingleDistanceMode] = useState(null)

  const distance_url = process.env.REACT_APP_DISTANCE_ENDPOINT; // Set your API key in the .env file

    useEffect(() => {
      setSingleDistanceMode(distanceMode)
    }, [distanceMode])

  useEffect(() => {
    const calculateDistance = async () => {
      try {
        const response1 = await fetch(distance_url + `?currentAddress=${encodeURIComponent(currentAddress)}&previousAddress=${encodeURIComponent(previousAddress)}&distanceMode=${singleDistanceMode}`);
        const data1 = await response1.json();

        // Extract the travel time in minutes from the Google Maps API response
        const durationInMinutes1 = Math.ceil(data1.rows[0].elements[0].duration.value / 60);
        // console.log(data1.rows[0].elements[0].distance.text, "🚗") ;

        // if (durationInMinutes1 > 60 && singleDistanceMode != 'driving') {
        //   const response2 = await fetch(distance_url + `?currentAddress=${encodeURIComponent(currentAddress)}&previousAddress=${encodeURIComponent(previousAddress)}&distanceMode=driving`);
        //   const data2 = await response2.json();

        //   // Extract the travel time in minutes from the Google Maps API response
        //   const durationInMinutes2 = Math.ceil(data2.rows[0].elements[0].duration.value / 60);

        //   setDistance(durationInMinutes2);
        // } else {
          setDistance(durationInMinutes1);
        // }
      } catch (error) {
        console.error('Error calculating distance:', error.message);
        setDistance(null);
      }
    };

    // Call the calculateDistance function
    calculateDistance();
  }, [currentAddress, previousAddress, distanceMode, singleDistanceMode]);


  const handleDistanceModeChange = (selectedOption) => {
    setSingleDistanceMode(selectedOption.value);
  };


  const getModeIcon = () => {
    switch (singleDistanceMode) {
      case 'driving':
        return faCar; // Replace with the desired icon for driving
      case 'walking':
        return faWalking; // Replace with the desired icon for walking
      case 'transit':
        return faTrain; // Replace with the desired icon for public transit
      default:
        return faCar; // Replace with the default icon
    }
  };

  return (
    <div className="d-flex align-items-center">
      {/* {distance > 20 ? ( */}
        <>
          <Select
            value={options.find((option) => option.value === singleDistanceMode)}
            onChange={handleDistanceModeChange}
            options={options}
            isSearchable={false}
            className="react-select-container"
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
          {distance && <span>{` ${distance} min`}</span>}
        </>
      {/* ) : (
        <div className="distance-component">
          <FontAwesomeIcon icon={getModeIcon()} className={`mode-icon ${getModeIcon()}`} />
          {distance !== null && (
            <span>{` ${distance} min`}</span>
          )}
        </div>

      )} */}

    </div>
  );
}

export default DistanceComponent;


const options = [
  { value: 'transit', label: <FontAwesomeIcon icon={faTrain} /> },
  { value: 'walking', label: <FontAwesomeIcon icon={faWalking} /> },
  { value: 'driving', label: <FontAwesomeIcon icon={faCar} /> },
];