import '../styles/App.css';
import {Col, Container, Row, Button} from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { useParams, withRouter } from "react-router";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Footer from '../components/Footer'
import Header from "../components/Header"
import HomepageInputs from "../components/HomepageInputs"
import HomepageFeatures from "../components/HomepageFeatures"
import HowItWorks from "../components/HowItWorks"
import Result from "../components/Result"
import GenerateTripButton from "../components/inputs/GenerateTripButton"
import { API } from "aws-amplify";
import { listTrips } from "../graphql/queries";
import ResultPlaceholder from "../components/ResultPlaceholder"


function Trip(props) {
    const [destination, setDestination] = useState('');
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [tripType, setTripType] = useState('');
    const [isRestaurants, setIsRestaurants] = useState(true);
    const [apiResponse, setApiResponse] = useState("");
    const [streamDone, setStreamDone] = useState(true);
    const [generateClicked, setGenerateClicked] = useState(false);
    const resultColRef = useRef(null);
    const modalRef = useRef(null);
	const params = useParams();


    async function fetchTrip(id) {
        try {
            const apiData = await API.graphql({
                query: listTrips,
                variables: {
                    filter: {
                        itineraryId: { eq: id }
                    }
                }
            });
    
            const tripFromAPI = apiData.data.listTrips.items;
    
            // Check if tripFromAPI has items before accessing properties
            if (tripFromAPI && tripFromAPI.length > 0) {
                setApiResponse(tripFromAPI[0].itinerary);
            } else {
                // Handle the case when the response doesn't contain any items
                console.error('No trip found for the given ID');
            }
        } catch (error) {
            // Handle any errors that occur during the API call
            console.error('Error fetching trip:', error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            await fetchTrip(params.id);
        }
        fetchData();
        if (!props.isAuthenticatorOpen && generateClicked && resultColRef.current) {
            setGenerateClicked(false);
            resultColRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [params.id, apiResponse]);

    return (
        <div
            className="App" id="#top"
            ref={modalRef}
        >
            <Header 
                openAuthenticatorModal={props.openAuthenticatorModal} 
                trips={props.trips} 
                setTrips={props.setTrips} 
            />
            <section className="text-left hero-section">
                <div className="body-try-it">
                    <Container
                        className='d-flex align-items-center'
                        >
                        <div className='w-100' >
                            <Row> 
                                {/* <Col 
                                    md={6}
                                    lg={6}
                                    xxl={4}
                                >
                                    <div className='position-relative h-100'>
                                        <div
                                            // className='sticky-card'
                                        >
                                            <div className='homepage-card your-trip mt-3'>
                                                <div>
                                                    <h3 className='mb-0'>Create <span className='text-one'>your itinerary in seconds</span> with the help of AI</h3>
                                                    <HomepageInputs
                                                        fromValue={fromValue}
                                                        setFromValue={setFromValue}
                                                        toValue={toValue}
                                                        setToValue={setToValue}
                                                        destination={destination}
                                                        setDestination={setDestination}
                                                        tripType={tripType}
                                                        setTripType={setTripType}
                                                        isRestaurants={isRestaurants}
                                                        setIsRestaurants={setIsRestaurants}
                                                    />
                                                </div>
                                                <GenerateTripButton
                                                    fromValue={fromValue}
                                                    toValue={toValue}
                                                    destination={destination}
                                                    tripType={tripType}
                                                    resultColRef={resultColRef}
                                                    apiResponse={apiResponse}
                                                    setStreamDone={setStreamDone}
                                                    setApiResponse={setApiResponse}
                                                    setGenerateClicked={setGenerateClicked}
                                                    openAuthenticatorModal={props.openAuthenticatorModal}
                                                    isAuthenticatorOpen={props.isAuthenticatorOpen}
                                                />
                                            </div>
                                            <div className='secondary-card'>
                                                <span>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} /> <b>This is a beta version that plans up to 7 days</b>. If you find any bug or would like to <b>suggest a feature</b> feel free to <a href="mailto:info@mytripassistant.com">contact us.</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col 
                                    md={6}
                                    lg={6}
                                    xxl={8}
                                >
                                    <ResultPlaceholder/>
                                </Col> */}
                                {apiResponse && (
                                    <Result
                                        trips={props.trips} 
                                        setTrips={props.setTrips} 
                                        apiResponse={apiResponse}
                                        destination={destination}
                                        streamDone={streamDone}
                                        openAuthenticatorModal={props.openAuthenticatorModal}
                                        isAuthenticatorOpen={props.isAuthenticatorOpen}
                                        modalRef={modalRef}
                                        resultColRef={resultColRef}
                                        isSaved={true}
                                    />
                                )}
                            </Row>
                            {/* <HowItWorks/>
                            <HomepageFeatures/> */}
                        </div>
                    </Container>
                </div>
            </section>


            <Footer/>

        </div>

    )
}

export default Trip;