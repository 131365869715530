import React from 'react'

const PickInterest = (props) => {

    const handleButtonClick = (index) => {
        props.setInterest(prev => prev.map((item, i) =>
            i === index ? { ...item, isSelected: !item.isSelected } : item
        ));
    };
    return (
        <div className='my-3'>
            <p className='my-3 text-start'>Pick the main interests for your trip.</p>
            <div className='d-flex flex-wrap gap-2 my-2 '>
                {
                    props.interest?.map((e, i) => (
                        <button onClick={() => handleButtonClick(i)} key={i} className={`interest-chip  ${e.isSelected && "interest-chip-selected"}`} > {e?.label}</button>
                    ))
                }
            </div>
        </div >
    )
}

export default PickInterest

