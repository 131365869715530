import React from "react";

function RestaurantsCheckbox(props) {
    return (
        <div className="restaurant-checkbox">
            <span className="pe-3">
                Include restaurants for meals
            </span>
            <input
                type="checkbox"
                checked={props.isRestaurants}
                onChange={() => props.setIsRestaurants((prev) => !prev)}
                className={props.isRestaurants ? "checked" : ""}
            />
        </div>
    );
}

export default RestaurantsCheckbox;