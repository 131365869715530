import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faArrowUpRightFromSquare, faDeleteLeft, faMapMarkerAlt, faPencil, faTicket, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/fontawesome-free-regular";
import placeholderImage from "../assets/images/placeholderImage.png";
import gygButton from "../assets/images/partners/gygButton.png";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import TimePickerActivities from "./inputs/TimePickerActivities";

const initialData = { activity: "", address: "", description: "", endTime: "", startTime: "" };
function ActivityCard(props) {
  const { isEdit = false, isAdd = false } = props;
  const [activityData, setActivityData] = useState(initialData);

  const [photoData, setPhotoData] = useState({
    photos: null,
    name: null,
    rating: 5,
  });

  const validateFields = () => {
    return Object.values(activityData).every((value) => value.trim());
  };

  useEffect(() => {
    if (isEdit) {
      setActivityData({
        activity: props.activity || "",
        address: props.address || "",
        description: props.description || "",
        endTime: props.endTime || "",
        startTime: props.startTime || "",
      });
    } else {
      setActivityData(initialData);
    }
  }, [isEdit, isAdd]);

  const handleInputChange = (e) => {
    let { value, name } = e.target;
    setActivityData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (props.address && props.description && props.startTime && props.endTime) {
      // fetchPhoto();
    }
  }, [props.address, props.description, props.startTime, props.endTime]);

  const fetchPhoto = () => {
    if (!window.google) {
      console.error("Google Maps API is not loaded.");
      return;
    }

    const request = {
      query: props.address,
      fields: ["name", "formatted_address", "place_id", "geometry", "photos", "rating"],
    };

    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
        const place = results[0];
        const photos = place.photos;

        setPhotoData({
          photos: photos && photos.length > 0 ? photos[0]?.getUrl() : null,
          name: place.formatted_address,
          rating: place.rating,
        });
      } else {
        const serv = new window.google.maps.places.PlacesService(document.createElement("div"));
        const req = {
          query: props.destination.address,
          fields: ["name", "formatted_address", "place_id", "geometry", "photos", "rating"],
        };
        serv.findPlaceFromQuery(req, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
            const place = results[0];
            const photos = place.photos;

            setPhotoData({
              photos: photos && photos.length > 0 ? photos[0]?.getUrl() : null,
              name: place.formatted_address,
              rating: place.rating,
            });
          } else {
            console.error("Error fetching backup photo:", status);
          }
        });
        console.error("Used backup photo:", status);
      }
    });
  };

  const { name, photos, rating } = photoData;

  const shouldRenderBookDiv = !/arrival|breakfast|dinner|lunch/i.test(props.activity);

  const addTrip = (selectedDay, activityIndex) => {
    if (!validateFields()) {
      toast.error("Fields must not be empty");
      return;
    }
    props.setResponse((prev) => {
      const updatedDays = [...prev.days];
      const updatedActivities = [...updatedDays[selectedDay].activities];
      updatedActivities[activityIndex] = activityData;
      updatedDays[selectedDay] = { ...updatedDays[selectedDay], activities: updatedActivities };
      return { days: updatedDays };
    });
    setActivityData(initialData);
  };

  const handleDeleteActivity = () => {
    props.setResponse((prev) => {
      const newDays = [...prev.days];
      newDays[props.selectedDay] = {
        ...newDays[props.selectedDay],
        activities: newDays[props.selectedDay].activities.filter((_, index) => index !== props.index),
      };
      return { days: newDays };
    });
    toast.success("Deleted Successfully")
  }

  // it remove added activity
  const handleRemove = () => {
    props.setResponse((prev) => {
      const newDays = [...prev.days];
      newDays[props.selectedDay] = {
        ...newDays[props.selectedDay],
        activities: newDays[props.selectedDay].activities.filter((_, index) => index !== props.index),
      };
      return { days: newDays };
    });
  };

  const handleRemoveEdit = () => {
    props.setResponse((prev) => {
      const newDays = [...prev.days];
      newDays[props.selectedDay] = {
        ...newDays[props.selectedDay],
        activities: newDays[props.selectedDay].activities.map((activity, index) =>
          index === props.index ? { ...activity, isEdit: false } : activity
        ),
      };
      return { days: newDays };
    });
  };

  return (
    <div className={props.blurred ? "activity-card blurred" : "activity-card"}>
      <div className="activity-content">
        {(!isEdit && !isAdd) && props.streamDone && (
          <div style={{ position: "absolute", right: "10px" }}>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={handleDeleteActivity}
              title="Edit Activity"
              style={{ outline: "none", cursor: "pointer", marginRight: "10px"}}
              data-tooltip-id="share-tooltip"
            />
            <FontAwesomeIcon
              icon={faPencil}
              onClick={() => props.editActivity(props.index)}
              title="Edit Activity"
              style={{ outline: "none", cursor: "pointer", marginRight: "10px"}}
              data-tooltip-id="share-tooltip"
            />
            <FontAwesomeIcon
              icon={faArrowsRotate}
              onClick={() => props.regenerateActivity(props.index, props.fullActivity)}
              title="Refresh Activity"
              style={{ outline: "none", cursor: "pointer" }}
              data-tooltip-id="share-tooltip"
            />
          </div>
        )}
        <div>
          {isAdd && <FontAwesomeIcon icon={faXmark} onClick={handleRemove} style={crossStyling} />}
          {isEdit && <FontAwesomeIcon icon={faXmark} onClick={handleRemoveEdit} style={crossStyling} />}
          {isAdd ? (
            <input
              className="trip-add-input w-100"
              placeholder="Activity Title"
              onChange={handleInputChange}
              value={activityData?.activity}
              name="activity"
            />
          ) : (
            <span className="activity-title">{props.activity}</span>
          )}
        </div>
        {!(isAdd || isEdit) && rating !== null && !isNaN(rating) ? (
          <span className="activity-rating">
            {rating.toFixed(1)}
            <span className="stars">
              <span style={{ width: `${(rating / 5) * 100}%` }}></span>
            </span>
          </span>
        ) : null}
        {isEdit || isAdd ? (
          <input
            className="trip-add-input w-100 mt-1"
            placeholder="Description"
            name="description"
            onChange={handleInputChange}
            value={activityData?.description}
          />
        ) : (
          <span className="activity-description">{props.description}</span>
        )}
        {isAdd ? (
          <div style={{ position: "relative" }}>
            <PlacesAutocomplete
              value={activityData.address}
              onChange={(address) => setActivityData((prev) => ({ ...prev, address: address }))}
              onSelect={(address) => setActivityData((prev) => ({ ...prev, address: address }))}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div style={{ position: "relative" }}>
                    <input
                      {...getInputProps({
                        placeholder: "Add another Destination",
                        className: "trip-add-input w-100 mt-1",
                      })}
                    />
                  </div>
                  <div className="autocomplete-dropdown-container">
                    {loading && <div className="suggestion-item">Loading...</div>}
                    {suggestions.map((suggestion, i) => {
                      const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                      return (
                        <div key={i} {...getSuggestionItemProps(suggestion, { className })}>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        ) : (
          <span className={`activity-address ${isEdit && "my-2"}`}>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> {props.address}
          </span>
        )}
        {isEdit || isAdd ? (
          //   <div className="d-flex justify-content-center gap-1 mt-1">
          //     <input placeholder="Start Time" className="trip-add-input w-100" name="startTime" onChange={handleInputChange} value={activityData?.startTime} />
          //     <input placeholder="End Time" className="trip-add-input w-100" name="endTime" onChange={handleInputChange} value={activityData?.endTime} />
          //   </div>
          <TimePickerActivities time={activityData} setTime={setActivityData} isEdit={isEdit} />
        ) : (
          <span className="activity-time">
            <FontAwesomeIcon icon={faCalendar} /> Time: {props?.startTime} - {props?.endTime}
          </span>
        )}
        {(isAdd || isEdit) && (
          <button className="buy-ticket-btn activity-btn w-100 mt-2" onClick={() => addTrip(props.selectedDay, props.index)}>
            {isAdd ? "Add Activity" : "Update Activity"}
          </button>
        )}
        {shouldRenderBookDiv && !isAdd && (
          <div className="activity-book">
            <a
              className="buy-ticket-btn activity-btn"
              href={`https://www.getyourguide.com/s/?et=51230&psrc=widget&partner_id=NHH18CD&utm_medium=travel_agent&currency=EUR&q=${encodeURIComponent(
                props.activity
              )}&queryMatch=all&widget=activities&wid=c279fbd9-5f25-5707-9cb3-38fbb1a4dd29&page_id=8139891a-8441-57ad-85e1-cc9eaa9053f1`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTicket} title="Share trip" data-tooltip-id="share-tooltip" />
              Buy Tickets
            </a>
            <a
              href={`https://www.google.com/maps?q=${encodeURIComponent(props.address)}`}
              target="_blank"
              rel="noreferrer"
              className="activity-btn google-map-btn"
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} title="Share trip" data-tooltip-id="share-tooltip" />
              Google Maps
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivityCard;

let crossStyling = {
  position: "absolute",
  top: "-10px",
  right: "-10px",
  fontSize: "1rem",
  color: "grey",
  background: "#d4d4d4",
  padding: "6px",
  borderRadius: "150%",
};
