import React, { useEffect, ChangeEventHandler, useState, useRef } from "react";
import styled from "styled-components";
import family from "../../assets/images/triptype/family.png";
import romantic from "../../assets/images/triptype/heart.png";
import cultural from "../../assets/images/triptype/parthenon.png";
import party from "../../assets/images/triptype/party-popper.png";
import adventure from "../../assets/images/triptype/tent.png";
import parse from "html-react-parser";

const CulturalIcon = styled.div`
    background-image: url(${cultural});
}
`;
const AdventureIcon = styled.div`
    background-image: url(${adventure});
}
`;
const RomanticIcon = styled.div`
    background-image: url(${romantic});
}
`;
const PartyIcon = styled.div`
    background-image: url(${party});
}
`;
const FamilyIcon = styled.div`
    background-image: url(${family});
}
`;

function TripTypes(props) {
  const renderCurrentSelection = () => {
    let text = ' a <span className="text-one" style="font-weight: 500">';
    props.tripType?.map((type, i) => {
      text += type.charAt(0).toUpperCase() + type.slice(1) + " Trip";
      if (i < props.tripType.length - 1) {
        text += ", ";
      }
    });
    text += "</span>";
    return text;
  };

  const _onChange = (type) => {
    let types = props.tripType;
    if (types.indexOf(type) >= 0) {
      types = types.filter((_type) => _type !== type);
      props.setTripType(types);
    } else {
      types.push(type);
      types = [...new Set(types)];

      props.setTripType(types);
    }
  };

  return (
    <div className="mt-4">
      <div className="text-start">
        For
        <span>{parse(renderCurrentSelection())}</span>
      </div>
      <div className="type-container mt-1">
        <div className="type-card">
          <input
            id="cultural"
            type="checkbox"
            name="trip_type"
            value="cultural"
            onChange={() => _onChange("cultural")}
            // checked={props.tripType === 'cultural'}
            checked={props.tripType?.indexOf("cultural") >= 0}
          />
          <label className="type-label" htmlFor="cultural">
            <CulturalIcon />
            <div className="description">
              <span>Cultural</span>
            </div>
          </label>
        </div>
        <div className="type-card">
          <input
            id="adventure"
            type="checkbox"
            name="trip_type"
            value="adventure"
            onChange={() => _onChange("adventure")}
            checked={props.tripType?.indexOf("adventure") >= 0}
          />
          <label className="type-label" htmlFor="adventure">
            <AdventureIcon />
            <div className="description">
              <span>Adventure</span>
            </div>
          </label>
        </div>
        <div className="type-card">
          <input
            id="romantic"
            type="checkbox"
            name="trip_type"
            value="romantic"
            onChange={() => _onChange("romantic")}
            checked={props.tripType?.indexOf("romantic") >= 0}
          />
          <label className="type-label" htmlFor="romantic">
            <RomanticIcon />
            <div className="description">
              <span>Romantic</span>
            </div>
          </label>
        </div>
        <div className="type-card">
          <input
            id="family"
            type="checkbox"
            name="trip_type"
            value="family"
            onChange={() => _onChange("family")}
            checked={props.tripType?.indexOf("family") >= 0}
          />
          <label className="type-label" htmlFor="family">
            <FamilyIcon />
            <div className="description">
              <span>Family</span>
            </div>
          </label>
        </div>
        <div className="type-card">
          <input
            id="party"
            type="checkbox"
            name="trip_type"
            value="party"
            onChange={() => _onChange("party")}
            checked={props.tripType?.indexOf("party") >= 0}
          />
          <label className="type-label" htmlFor="party">
            <PartyIcon />
            <div className="description">
              <span>Party</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default TripTypes;
