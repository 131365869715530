import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from '@fortawesome/fontawesome-free-regular';
import { Tooltip } from 'react-tooltip'

function ItineraryMap(props) {

  const [currentLocation, setCurrentLocation] = useState(null);
  const [pins, setPins] = useState([]);
  const [map, setMap] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  useEffect(() => {

    // Function to get coordinates from the address using Google Maps Geocoding API
    const getCoordinatesFromAddress = async (address) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyAJyLfqDOejJl1SfhEYNKSyWfjZEA6eaE0`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          return {
            lat: location.lat,
            lng: location.lng,
          };
        } else {
          console.error("Invalid address", address);
          return null;
        }
      } catch (error) {
        console.error("Error during geocoding request", error);
        return null;
      }
    };

    if (props.mainDestination && !currentLocation) {
      getCoordinatesFromAddress(props.mainDestination[0].address).then((location) => {
        setCurrentLocation(location);
      });
    } else if (!props.mainDestination && !currentLocation) {
      getCoordinatesFromAddress(props.activities[0].address).then((location) => {
        setCurrentLocation(location);
      });
    }

    // Update pins when the selected day changes
    const updatePins = async () => {
      if (props.streamDone) {
        const pins = await Promise.all(
          props.activities.map(async (activity) => {
            const coordinates = await getCoordinatesFromAddress(activity.address);
            return { ...coordinates, title: activity.activity };
          })
        );
        setPins(pins.filter((pin) => pin !== null));

        if (directionsRenderer) {
          directionsRenderer.setMap(null);
        }

        if (map && currentLocation && pins.length > 1) {
          const newDirectionsRenderer = new window.google.maps.DirectionsRenderer();
          newDirectionsRenderer.setMap(map);

          const directionsService = new window.google.maps.DirectionsService();
          const origin = pins[0];
          const destination = pins[pins.length - 1];

          const waypoints = pins.slice(1, -1).map((pin) => ({
            location: new window.google.maps.LatLng(pin.lat, pin.lng),
            stopover: true,
          }));

          directionsService.route(
            {
              origin: origin,
              destination: destination,
              waypoints: waypoints,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                newDirectionsRenderer.setDirections(result);
                setDirectionsRenderer(newDirectionsRenderer);
              } else {
                console.error(`Error retrieving directions: ${status}`);
              }
            }
          );
        }
      }
    };


    // Update pins and directions when the selected day changes
    updatePins();

  }, [props.mainDestination, props.activities]);


  const openGoogleMaps = () => {
    if (props.streamDone) {
      const origin = `${currentLocation.lat},${currentLocation.lng}`;
      const destination = `${pins[pins.length - 1].lat},${pins[pins.length - 1].lng}`;
      const waypoints = pins.slice(1, -1).map((pin) => `${pin.lat},${pin.lng}`).join('|');

      const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints}`;

      window.open(mapsUrl, '_blank');
    }
  };

  const apiIsLoaded = (map, maps) => {
    setMap(map);
  };

  return (
    <div className="result-map-container">
      <div className="result-map">
        <FontAwesomeIcon onClick={openGoogleMaps} className="open-on-maps-icon" icon={faMap} title="Open in Google Maps" data-tooltip-id="open-tooltip" data-tooltip-content="Open in Google Maps" />
        <Tooltip id="open-tooltip" style={{ borderRadius: "8px", zIndex: "100000" }} />
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAJyLfqDOejJl1SfhEYNKSyWfjZEA6eaE0",
          }}
          defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
          defaultZoom={10}
          center={currentLocation || { lat: 40.756795, lng: -73.954298 }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        >
          {pins.map((pin, index) => (
            <Pin key={index} lat={pin.lat} lng={pin.lng} title={pin.title} />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
}

const Pin = ({ title }) => (
  <div className="pin">
    <div className="pin-text">{title}</div>
  </div>
);

export default ItineraryMap;