import "../styles/App.css";
import { Col, Container, Row, Button, Card, Nav } from "react-bootstrap";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HomepageInputs from "../components/HomepageInputs";
import HomepageFeatures from "../components/HomepageFeatures";
import HowItWorks from "../components/HowItWorks";
import Result from "../components/Result";
import GenerateTripButton from "../components/inputs/GenerateTripButton";
import ResultPlaceholder from "../components/ResultPlaceholder";
import BookedFlight from "../components/BookedFlight";
import { ToastContainer } from "react-toastify";

const Home = (props) => {
  const [destination, setDestination] = useState([]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [tripType, setTripType] = useState([]);
  const [interest, setInterest] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [isRestaurants, setIsRestaurants] = useState(true);
  const [dietaryPreferences, setDietaryPreferences] = useState([]);
  const [streamDone, setStreamDone] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [migrationTime, setMigrationTime] = useState({});
  const [generateClicked, setGenerateClicked] = useState(false);
  const [anyThingElse, setAnyThingElse] = useState("")
  const resultColRef = useRef(null);
  const modalRef = useRef(null);
  const [flightDetail, setFlightDetail] = useState({
    flightBooked: '',
    needAccommodation: 'yes',
    rentCar: '',
    transferAirport: '',
  });
  const [localFlightDetail, setLocalFlightDetail] = useState(flightDetail);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [time, setTime] = useState({
    startTime: "",
    endTime: ""
  })

  useEffect(() => {
    if (!props.isAuthenticatorOpen && generateClicked && resultColRef.current) {
      setGenerateClicked(false);
      resultColRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [apiResponse]);


  const airportScriptRef = useRef(false);

  const addScript = useCallback((containerRef, scriptRef, src, onLoadMessage) => {
    try {
      const container = document.getElementById(containerRef);
      if (container && !scriptRef.current) {
        const existingScript = container.querySelector(`script[src="${src}"]`);
        if (!existingScript) {
          const script = document.createElement('script');
          script.async = true;
          script.src = src;
          script.onload = () => {
            console.log(onLoadMessage);
            scriptRef.current = true;
          };
          container.appendChild(script);
        } else {
          console.log(`${onLoadMessage} (already loaded)`);
          scriptRef.current = true;
        }
      }
    } catch (error) {
      console.error("🚀 ~ addScript ~ error:", error);
    }
  }, []);

  useEffect(() => {
    const airportSrc = `https://c147.travelpayouts.com/content?trs=288569&shmarker=509535&locale=en&powered_by=false&promo_id=8579`;
    addScript("airport-widget-container", airportScriptRef, airportSrc, 'airport.com script loaded 👍');
  }, [addScript]);


  // useLayoutEffect(() => {
  //     console.log(generateClicked);
  //     if (!props.isAuthenticatorOpen && generateClicked && resultColRef.current) {
  //         resultColRef.current.scrollIntoView({ behavior: "smooth" });
  //     }
  // }, [generateClicked, props.isAuthenticatorOpen]);

  return (
    <div className="App" id="#top" ref={modalRef}>
      <Header openAuthenticatorModal={props.openAuthenticatorModal} trips={props.trips} setTrips={props.setTrips} />
      <section className="text-left hero-section">
        <div className="body-try-it">
          <Container className="mt-md-5 d-flex align-items-center">
            <div className="w-100">
              <Row>
                <Col md={6} lg={6} xxl={4}>
                  <div className="position-relative h-100 mt-3">
                    <div
                    >
                      <div className="homepage-card your-trip ">
                        <div>
                          <h3 className="mb-0">
                            Create <span className="text-one">your dream itinerary in seconds</span>
                          </h3>
                          <HomepageInputs
                            filterLoading={filterLoading}
                            fromValue={fromValue}
                            setFromValue={setFromValue}
                            toValue={toValue}
                            setToValue={setToValue}
                            destination={destination}
                            setDestination={setDestination}
                            tripType={tripType}
                            setTripType={setTripType}
                            isRestaurants={isRestaurants}
                            setIsRestaurants={setIsRestaurants}
                            dietaryPreferences={dietaryPreferences}
                            setDietaryPreferences={setDietaryPreferences}
                            interest={interest}
                            setInterest={setInterest}
                            anyThingElse={anyThingElse}
                            setAnyThingElse={setAnyThingElse}
                            migrationTime={migrationTime}
                            setFlightDetail={setLocalFlightDetail}
                            flightDetail={localFlightDetail}
                            arrivalDate={arrivalDate}
                            departureDate={departureDate}
                            setArrivalDate={setArrivalDate}
                            setDepartureDate={setDepartureDate}
                            time={time}
                            setTime={setTime}
                          />
                        </div>
                        <GenerateTripButton
                          setFilterLoading={setFilterLoading}
                          fromValue={fromValue}
                          toValue={toValue}
                          destination={destination}
                          tripType={tripType}
                          apiResponse={apiResponse}
                          setStreamDone={setStreamDone}
                          setApiResponse={setApiResponse}
                          setGenerateClicked={setGenerateClicked}
                          openAuthenticatorModal={props.openAuthenticatorModal}
                          isAuthenticatorOpen={props.isAuthenticatorOpen}
                          isRestaurants={isRestaurants}
                          dietaryPreferences={dietaryPreferences}
                          anyThingElse={anyThingElse}
                          interest={interest}
                          setInterest={setInterest}
                          localFlightDetail={localFlightDetail}
                          setFlightDetail={setFlightDetail}
                          arrivalDate={arrivalDate}
                          departureDate={departureDate}
                          time={time}
                          setTime={setTime}
                        />
                      </div>


                      {/* <div className="secondary-card">
                        <span>
                          <FontAwesomeIcon icon={faExclamationTriangle} /> <b>This is a beta version that plans up to 7 days</b>. If you find any bug
                          or would like to <b>suggest a feature</b> feel free to <a href="mailto:info@mytripassistant.com">contact us.</a>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6} xxl={8}>
                  <ResultPlaceholder />
                </Col>

                {/* <Col xxl={12} style={{ margin: "30px 0" }}>
                  <BookedFlight
                    destination={destination}
                    generateClicked={generateClicked}
                  />
                </Col> */}

                {/* <div id="trip-widget-container" style={{ display: flightDetail?.flightBooked === "no" ? "block" : "none" }}></div>
                <div id="car-widget-container" style={{ display: flightDetail?.rentCar === "yes" ? "block" : "none" }}></div>

                <div id="airport-widget-container"
                  style={{ display: (flightDetail?.rentCar === "no" && props.flightDetail?.transferAirport == "yes") ? "block" : "none" }}></div> */}

                <div style={{ display: apiResponse ? "block" : "none" }}>
                  <Result
                    trips={props.trips}
                    setTrips={props.setTrips}
                    apiResponse={apiResponse}
                    setApiResponse={setApiResponse}
                    destination={destination}
                    streamDone={streamDone}
                    openAuthenticatorModal={props.openAuthenticatorModal}
                    isAuthenticatorOpen={props.isAuthenticatorOpen}
                    modalRef={modalRef}
                    flightDetail={flightDetail}
                    resultColRef={resultColRef}
                    generateClicked={generateClicked}
                    time={time}
                  />
                </div>
              </Row>
              <HowItWorks />
              <HomepageFeatures />
            </div>
          </Container>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Home;

const interests = [
  { label: "Ancient history" },
  { label: "Italian cuisine" },
  { label: "Wine tasting" },
  { label: "Pizza making" },
  { label: "Vespa tour" },
  { label: "Colosseum visit" },
  { label: "Art galleries" },
  { label: "Shopping" },
  { label: "Gelato tasting" },
  { label: "Theater show" },
  { label: "Rooftop views" },
  { label: "Walking tours" },
  { label: "Vatican City" },
  { label: "Aperitivo" },
  { label: "Gardens" }
];