import { format, isAfter, isBefore, parse } from "date-fns";
import { useLocation } from "react-router-dom";

function CustomPrompt(props) {
  const { toValue, fromValue, destination, isRestaurants, tripType, dietaryPreferences, anyThingElse, interest, time } = props;

  const selectedInterest = interest?.filter((int) => int?.isSelected);
  const location = useLocation();

  const formattedDestination = destination.map((des) => des.address).join(" to ");
  const formattedTripType = tripType?.map((type) => tripTypeCondition[type]).join(", ");
  const formattedStartTime = time.startTime ? format(time.startTime, "hh:mm a") : "";
  const formattedEndTime = time.endTime ? format(time.endTime, "hh:mm a") : "";
  const formattedDietaryPreferences = dietaryPreferences.join(", ");
  const formattedInterest = selectedInterest?.map((int) => int.label).join(", ");
  const formatString = "hh:mm a";
  const parsedStartTime = parse(formattedStartTime, formatString, new Date());
  const parsedEndTime = parse(formattedEndTime, formatString, new Date());
  const noonTime = parse("12:00 PM", formatString, new Date());
  const eveningTime = parse("07:00 PM", formatString, new Date());

  // start time
  const isAfterNoon = isAfter(parsedStartTime, noonTime);
  const isEvening = isAfter(parsedStartTime, eveningTime);

  // end time

  const isEndAfterNoon = isBefore(parsedEndTime, noonTime);
  const isEndEvening = isBefore(parsedEndTime, eveningTime);

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const destinationFromUrl = queryParams.get("destination");

  const promptForItinerary = `
Create a detailed itinerary for a trip to ${formattedDestination}. We will land on ${fromValue} and depart on ${toValue}. 
${formattedStartTime && formattedEndTime ? `The trip starts at ${formattedStartTime} and ends at ${formattedEndTime}.` : ""} 
All times mentioned in UTC 0.${
    tripType?.length ? `The trip is categorized as ${formattedTripType}.` : ""
  } The goal is to see all the best and most iconic places. 
${tripType?.length ? `This is a ${tripType.join(", ")} trip, so tailor the itinerary to these preferences.` : ""} 
Include suggestions for local places for breakfast, lunch, and dinner, specifying the name and address of each restaurant or cafe. 
${dietaryPreferences.length ? `Suggest ${formattedDietaryPreferences} food options.` : ""} 
${selectedInterest?.length ? `I have an interest in ${formattedInterest}.` : ""}
${
  formattedStartTime && formattedEndTime
    ? `
  For the First Day:
  - Start the first activity exactly at ${formattedStartTime}.
  ${isAfterNoon && !isEvening ? "- Skip breakfast and morning activities." : ""}  
  ${isEvening ? "- Skip breakfast, lunch and morning,afternoon activities." : ""}
  - Begin scheduling from the first activity that fits within the time frame after ${formattedStartTime}.
  
  For the Last Day:
  - End the last activity exactly at ${formattedEndTime}.
  ${isEndAfterNoon && isEndEvening ? "- Add only breakfast and morning activities. Skip lunch and etc" : ""} 
  ${!isEndAfterNoon && isEndEvening ? `- Add breakfast and lunch activities and skip activities that after ${formattedEndTime}.` : ""} 
  ${!isEndEvening ? `-Add breakfast , lunch , afternoon activities` : ""}  
  - Adjust the schedule to include only activities that fit before ${formattedEndTime}.

### Other Days:
Each day should include a maximum of eight activities scheduled in the following strict chronological order:
  - Breakfast 
  - First activity
  - Second activity
  - Lunch
  - Third activity
  - Fourth activity
  - Dinner
  - Final activity 

Manage the activities based on the trip start time of ${formattedStartTime} and the trip end time of ${formattedEndTime}. Provide a clear and concise format, and strictly adhere to the order and time constraints. The itinerary must follow the instructions provided.`
    : `
Each day should include a maximum of eight activities scheduled in the following strict chronological order:
  - Breakfast  
  - First activity
  - Second activity
  - Lunch
  - Third activity
  - Fourth activity
  - Dinner
  - Final activity 
`
}
${anyThingElse}
`;

  console.log(promptForItinerary);

  return promptForItinerary;
}
// ${formattedStartTime && formattedEndTime ? `Ensure that activities fit within the specified time frame from ${formattedStartTime} to ${formattedEndTime}` : ""}.

export default CustomPrompt;

const tripTypeCondition = {
  family: "for a family",
  romantic: "for a couple",
  cultural: "for cultural",
  adventure: "for exciting adventure",
  party: "for party",
};
