import React, {useEffect, ChangeEventHandler, useState, useRef} from "react";
import {Col, Row } from "react-bootstrap";
import '../styles/inputs.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faMapMarkedAlt, faStreetView } from "@fortawesome/free-solid-svg-icons";
import how_1 from "../assets/images/howitworks/how_1.png";
import how_2 from "../assets/images/howitworks/how_2.png";
import how_3 from "../assets/images/howitworks/how_3.png";

function HowItWorks(props) {


    return (
            <Row id="howitworks">
                <h2>How it <span className='text-one'>works</span></h2>
                <Col
                    md={12}
                    lg={12}
                    xxl={4}>
                        <div className="works-card">
                            <div className="works-left">
                                <div className="works-num">1</div>
                                <span className="works-txt">Enter your destination, travel dates and the type of trip</span>
                            </div>
                            <img
                                src={how_1}
                                width="250px"
                                className="d-inline-block align-top"
                            />
                        </div>
                </Col>
                <Col
                    md={12}
                    lg={12}
                    xxl={4}>
                        <div className="works-card">
                            <div className="works-left">
                                <div className="works-num">2</div>
                                <span className="works-txt">Your itinerary will be crafted from 1000's of handpicked attractions, accommodations, restaurants, and travel tips.</span>
                            </div>
                            <img
                                src={how_2}
                                width="250px"
                                className="d-inline-block align-top"
                            />
                        </div>
                </Col>
                <Col
                    md={12}
                    lg={12}
                    xxl={4}>
                        <div className="works-card">
                            <div className="works-left">
                                <div className="works-num">3</div>
                                <span className="works-txt">Share your itinerary with travel buddies, friends and family! (coming soon)</span>
                            </div>
                            <img
                                src={how_3}
                                width="250px"
                                className="d-inline-block align-top"
                            />
                        </div>
                </Col>
            </Row>
    )
}

export default HowItWorks;