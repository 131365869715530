import React, { useState, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Trip from "./views/Trip";
import AuthModal from "./components/AuthModal";
import { Amplify } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsConfig from "./aws-exports";
import TagManager from 'react-gtm-module'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const tagManagerArgs = {
    gtmId: 'GTM-T2DR2XRH'
}

TagManager.initialize(tagManagerArgs)
Amplify.configure(awsConfig)

function App() {
  const [isAuthenticatorOpen, setIsAuthenticatorOpen] = useState(false);
  const [trips, setTrips] = useState([]);

  const openAuthenticatorModal = () => {
    setIsAuthenticatorOpen(true);
  };

  const closeAuthenticatorModal = () => {
    setIsAuthenticatorOpen(false);
  };

  return (
    <Authenticator.Provider>
        <div className={"App"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                      <Home 
                        openAuthenticatorModal={openAuthenticatorModal} 
                        isAuthenticatorOpen={isAuthenticatorOpen} 
                        trips={trips} 
                        setTrips={setTrips} 
                      />
                    } />
                    <Route path="/trip/:id" element={
                      <Trip
                        openAuthenticatorModal={openAuthenticatorModal} 
                        isAuthenticatorOpen={isAuthenticatorOpen}
                        trips={trips} 
                        setTrips={setTrips} 
                      />
                    } />
                </Routes>
            </BrowserRouter>
            <AuthModal
              isAuthenticatorOpen={isAuthenticatorOpen}
              closeAuthenticatorModal={closeAuthenticatorModal}
            />
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        </div>
    </Authenticator.Provider>
  );
}

export default App;