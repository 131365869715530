// import React, { useEffect, useRef, useCallback, useMemo } from 'react';

// const Widgets = ({ flightDetail, destination }) => {
//     const tripWidgetRef = useRef(null);
//     const carWidgetRef = useRef(null);
//     const airportWidgetRef = useRef(null);

//     const addScript = useCallback((containerRef, scriptRef, src, onLoadMessage) => {
//         try {
//             const container = containerRef.current;
//             if (container && !scriptRef.current) {
//                 const script = document.createElement('script');
//                 script.async = true;
//                 script.src = src;
//                 script.onload = () => {
//                     console.log(onLoadMessage);
//                     scriptRef.current = true; // Mark script as loaded
//                 };
//                 container.appendChild(script);
//             }
//         } catch (error) {
//             console.error("🚀 ~ addScript ~ error:", error);
//         }
//     }, []);

//     const tripScriptRef = useRef(false);
//     const carScriptRef = useRef(false);
//     const airportScriptRef = useRef(false);

//     useEffect(() => {
//         console.log("rerender")
//         const tripWidgetSrc = `https://tp.media/content?trs=288569&shmarker=509535&locale=en&curr=USD&powered_by=false&border_radius=0&plain=true&color_button=%2337ab31&color_button_text=%23ffffff&color_border=%2337ab31&promo_id=4132&campaign_id=121&default_origin=${encodeURIComponent(destination[0]?.address || '')}`;
//         const rentCarSrc = "https://c172.travelpayouts.com/content?trs=288569&shmarker=509535&locale=en&powered_by=true&promo_id=4850";
//         const airportSrc = `https://c147.travelpayouts.com/content?trs=288569&shmarker=509535&locale=en&powered_by=false&promo_id=8579`;

//         // Clear existing widgets and load new scripts
//         if (flightDetail?.rentCar === "yes") {
//             // carWidgetRef.current.innerHTML = '';
//             addScript(carWidgetRef, carScriptRef, rentCarSrc, 'car.com script loaded 👍');
//         }

//         if (flightDetail?.flightBooked === "no") {
//             // tripWidgetRef.current.innerHTML = '';
//             addScript(tripWidgetRef, tripScriptRef, tripWidgetSrc, 'trip.com script loaded 👍');
//         }

//         if (flightDetail?.rentCar === "no" && flightDetail?.transferAirport === "yes") {
//             // airportWidgetRef.current.innerHTML = '';
//             addScript(airportWidgetRef, airportScriptRef, airportSrc, 'airport.com script loaded 👍');
//         }
//     }, [destination.address, flightDetail.flightBooked,flightDetail.rentCar,flightDetail.transferAirport ,addScript]);

//     return (
//         <>
//             <div
//                 id="trip-widget-container"
//                 ref={tripWidgetRef}
//                 style={{ display: flightDetail?.flightBooked === "no" ? "block" : "none", margin: "30px 0" }}
//             ></div>
//             <div
//                 id="car-widget-container"
//                 ref={carWidgetRef}
//                 style={{ display: flightDetail?.rentCar === "yes" ? "block" : "none", margin: "30px 0" }}
//             ></div>
//             <div
//                 id="airport-widget-container"
//                 ref={airportWidgetRef}
//                 style={{ display: (flightDetail?.rentCar === "no" && flightDetail?.transferAirport === "yes") ? "block" : "none", margin: "30px 0" }}
//             ></div>
//         </>
//     );
// };

// export default Widgets;

import React from "react";
import TripWidget from "./TripWidget";
import CarWidget from "./CarWidget";
import AirportWidget from "./AirportWidget";
import { Accordion } from "react-bootstrap";

const Widgets = ({ flightDetail, destination }) => {
  return (
    <>
      <Accordion  style={{margin: "20px 0" , borderTop: "1px !important"}}>
            {flightDetail?.flightBooked === "no" && 
        <Accordion.Item eventKey="1">
          <Accordion.Header  style={{margin : "0px"}}>Flights</Accordion.Header>
          <Accordion.Body>
            <TripWidget destination={destination} />
          </Accordion.Body>
        </Accordion.Item>
            }
        <Accordion.Item eventKey="0" style={{ display: (flightDetail?.rentCar === "no" && flightDetail?.transferAirport === "yes") ? "block" : "none" }}>
          <Accordion.Header style={{margin : "0px"}}>Airport Transfer Service</Accordion.Header>
          <Accordion.Body>
            <div id="airport-widget-container" style={{ display: (flightDetail?.rentCar === "no" && flightDetail?.transferAirport === "yes") ? "block" : "none" }}></div>
          </Accordion.Body>
        </Accordion.Item>
        {flightDetail?.rentCar === "yes" &&
        <Accordion.Item eventKey="2">
          <Accordion.Header style={{margin : "0px"}}>Car Rental</Accordion.Header>
          <Accordion.Body>
             <CarWidget />
          </Accordion.Body>
        </Accordion.Item>
            }
      </Accordion>
    </>
  );
};

export default Widgets;
