import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "react-tooltip";
import { Col, Dropdown, Row } from "react-bootstrap";
import ActivityCard from "../components/ActivityCard";
import DistanceComponent from "../components/DistanceComponent";
import DistanceModeSelector from "../components/DistanceModeSelector";
import ItineraryMap from "../components/ItineraryMap";
import ShareButtons from "../components/ShareButtons";
import ActivitiesOverlay from "../components/ActivitiesOverlay";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { createTrip as createTripMutation } from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faSave, faMap, faDownload, faPlus, faEdit, faPencil, faArrowsRotate, faTicket } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/fontawesome-free-regular";
import { fetchTrips } from "../components/Header"; // Adjust the path accordingly
import { parse } from "best-effort-json-parser";
import Widgets from "./Widgets";
import ExportItinerary from "./ExportItinerary";
import { toast } from "react-toastify"; // Ensure you import toast
import { format } from "date-fns";

const Result = forwardRef((props, ref) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate(); // Initialize useHistory
  const [selectedDay, setSelectedDay] = useState(0);
  const dayUpRef = useRef(null);
  const mapRef = useRef(null);
  const [showSave, setShowSave] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [tripName, setTripName] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [inputShareRef, setInputShareRef] = useState(null);
  const [loginInitiatedFromSave, setLoginInitiatedFromSave] = useState(false); // New state
  const [distanceMode, setDistanceMode] = useState("walking"); // Imposta la modalità di trasporto predefinita a 'driving'
  const [response, setResponse] = useState("");
  const [newActivity, setNewActivity] = useState(null);

  const handleCloseSave = () => {
    setShowSave(false);
    setConfirmationMessage(""); // Reset confirmation message
    setLoginInitiatedFromSave(false);
  };
  const handleShowSave = () => setShowSave(true);

  useEffect(() => {
    if (authStatus === "authenticated") {
      // Call the authentication success callback
      handleAuthenticationSuccess();
    }
    if (props.apiResponse && props.apiResponse.length > 0) {
      // setResponse(filterActivities(parse(props.apiResponse)));
      setResponse(parse(props.apiResponse));
    }
  }, [authStatus, loginInitiatedFromSave, props.streamDone, props.apiResponse, selectedDay, distanceMode, props.time]);

  const filterActivities = (itinerary) => {
    const formatTime = (time) => parseInt(time?.replace(":", ""), 10);
    let startTime = format(props.time.startTime, "HH:mm");
    let endTime = format(props.time.endTime, "HH:mm");

    const filteredDays = itinerary?.days?.map((day, index) => {
      if (index === 0) {
        // First day
        day.activities = day?.activities?.filter((activity) => formatTime(activity?.startTime) >= formatTime(startTime));
      } else if (index === itinerary?.days?.length - 1) {
        // Last day
        day.activities = day?.activities?.filter((activity) => formatTime(activity?.endTime) <= formatTime(endTime));
      }
      return day;
    });

    return { days: filteredDays };
  };

  const handleSaveClick = async (event) => {
    event.preventDefault();
    if (authStatus !== "authenticated") {
      props.openAuthenticatorModal();
      setLoginInitiatedFromSave(true);
    } else {
      if (props.streamDone) {
        handleShowSave();
      }
    }
  };

  const scrollToMap = async (event) => {
    event.preventDefault();
    if (mapRef.current) {
      setTimeout(() => {
        mapRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleAuthenticationSuccess = () => {
    // Callback to handle successful authentication
    // Only trigger if login was initiated from handleSaveClick
    if (loginInitiatedFromSave) {
      handleShowSave();
    }
  };

  const handleSave = async (e) => {
    const createdTrip = await createTrip();
    setConfirmationMessage("Trip saved successfully!");
    handleCloseSave();
    const userTrips = await fetchTrips(user.username);
    props.setTrips(userTrips);
    navigate(`/trip/${createdTrip.itineraryId}`);
  };

  const handleShowShare = () => setShowShare(true);
  const handleCloseShare = () => setShowShare(false);

  const openShareModal = (event) => {
    if (props.isSaved) {
      event.preventDefault();
      handleShowShare();
    }
  };

  const handleCopy = () => {
    inputShareRef.select();
    document.execCommand("copy");
    setConfirmationMessage("Copied!");
    setTimeout(() => {
      setConfirmationMessage("");
    }, 3000); // Set the duration for the tooltip message
  };

  const scrollingDay = () => {
    if (dayUpRef.current) {
      setTimeout(() => {
        dayUpRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleDayClick = (dayIndex, scroll) => {
    setSelectedDay(dayIndex);
    if (scroll) {
      scrollingDay();
    }
  };

  async function createTrip() {
    const data = {
      author: user.username,
      itineraryId: uuidv4(),
      itineraryName: tripName,
      itinerary: props.apiResponse,
      visibility: "public",
    };
    const result = await API.graphql({
      query: createTripMutation,
      variables: { input: data },
    });
    return result.data.createTrip; // Return the created trip data
  }

  const handleAddActivity = (index) => {
    let itinerary = [...response?.days];
    itinerary = itinerary.map((day) => ({
      ...day,
      activities: day.activities.map((activity) => ({
        ...activity,
        isEdit: false,
      })),
    }));
    itinerary[selectedDay].activities = itinerary[selectedDay]?.activities?.filter((activity) => !activity.isAdd);
    itinerary[selectedDay]?.activities?.splice(index, 0, { isAdd: true });
    setResponse({ days: itinerary });
  };

  const editActivity = (index) => {
    let itinerary = [...response?.days];
    if (itinerary[selectedDay]?.activities) {
      let filteredActivities = itinerary[selectedDay].activities.filter((activity) => !activity.isAdd);
      itinerary[selectedDay].activities = filteredActivities.map((activity, i) => ({
        ...activity,
        isEdit: i === index, // Set isEdit to true only for the specified index
      }));
    }
    setResponse({ days: itinerary });
  };

  const regenerateActivity = async (activityIndex, activity) => {
    // Its a function that returns a promise for toast
    const fetchSuggestion = async () => {
      try {
        const result = await fetch(process.env.REACT_APP_TRIP_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: `Generate a new suggestion for an activity that is the same type as the current activity (e.g., if the current activity is lunch, suggest another lunch) at a different but nearest location, and doesn't change time. If the current activity is not lunch, dinner, or breakfast, do not suggest another meal activity. ${JSON.stringify(
              activity
            )}`,
            system:
              "Generate a new suggestion for an activity.The activity type should strictly match the type of the current activity (e.g., if the current activity is lunch, suggest another lunch) at a different but nearest location. Return the response formatted as an object. The object should include the following keys: 1. activity: the title of the activity, 2. address: formatted as 'location-name, address with street and number', 3. description: a short description of at least 30 words about the activity, 4. startTime: formatted as 'hh', 5. endTime: formatted as 'hh'. When the activity is breakfast, lunch, or dinner, always include the name of the restaurant or cafe in the activity title and address, Provide only one activity.",
          }),
        });

        const reader = result.body.pipeThrough(new TextDecoderStream()).getReader();
        let apiResponse = "";

        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            let itinerary = [...response?.days];
            itinerary = itinerary.map((day) => ({
              ...day,
              activities: day.activities.map((activity) => ({ ...activity })),
            }));
            itinerary[selectedDay].activities[activityIndex] = parse(apiResponse);
            setResponse({ days: itinerary });
            return; // Resolve the promise successfully
          }

          apiResponse += value;
        }
      } catch (error) {
        console.error("😡 ~ regenerateActivity ~ error:", error);
        throw error; // Reject the promise if an error occurs
      }
    };

    // Use toast.promise to handle the promise states
    toast.promise(fetchSuggestion(), {
      pending: "Generating",
      success: "Generated",
      error: "Failed to generate",
    });
  };

  const addActivityWithAi = async (activityIndex) => {
    const getActivity = async () => {
      try {
        const result = await fetch(process.env.REACT_APP_TRIP_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: `I'm planning my trip to ${props.destination[0]?.address}, Pakistan. Can you suggest any activity, including a short description, address, and appropriate timings? Please provide only one activity, not related to meals.`,
            system: `You are a travel assistant responsible for generating personalized trip plans. When provided with details about an activity, location, and user preferences, respond with the following JSON-like format:
  - "activity": The title of the activity.
  - "address": Format the address as "location-name, address with street and number, and zip code".
  - "description": A short description of at least 20 words about the activity, including key features or experiences.
  - "startTime": The start time of the activity in "hh:mm" format.
  - "endTime": The end time of the activity in "hh:mm" format.
  
  Ensure the response strictly follows this structure:
  
  { "activity": "Activity Name", "address": "location-name, street address, city, zip code", "description": "Short description of the activity", "startTime": "hh:mm", "endTime": "hh:mm" }
  `,
          }),
        });

        const reader = result.body.pipeThrough(new TextDecoderStream()).getReader();
        let apiResponse = "";

        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            let itinerary = [...response?.days];
            itinerary[selectedDay]?.activities?.splice(activityIndex, 0, parse(apiResponse));
            setResponse({ days: itinerary });
            break;
          }

          apiResponse += value;
        }
      } catch (error) {
        console.log("😡 ~ regenerateActivity ~ error:", error);
      }
    };

    toast.promise(getActivity(), {
      pending: "Generating",
      success: "Generated",
      error: "Failed to generate",
    });
  };

  return (
    <Row className="result-container" ref={props.resultColRef}>
      <Col md={12} lg={12} xxl={12}>
        <h2>
          Your <span className="text-one">Itinerary</span>
        </h2>
      </Col>
      <Col md={6} lg={6} xxl={7}>
        <div className="result-column">
          <div className="result-toolbar">
            <div className="days-container" ref={dayUpRef}>
              {response?.days?.map((day, index) => (
                <div key={index} className={index === selectedDay ? "day selected" : "day"} onClick={() => handleDayClick(index, false)}>
                  Day {index + 1}
                </div>
              ))}
            </div>
            <div className="tools-container">
              <DistanceModeSelector distanceMode={distanceMode} setDistanceMode={setDistanceMode} />
              <div className="scroll-to-map" onClick={(event) => scrollToMap(event)}>
                <FontAwesomeIcon icon={faMap} title="Show Map" data-tooltip-id="map-tooltip" data-tooltip-content="Show Map" />
                <Tooltip id="map-tooltip" style={{ borderRadius: "8px", zIndex: "100000" }} />
              </div>
              <div className="save-trip" onClick={(event) => handleSaveClick(event)}>
                Save trip{" "}
                <FontAwesomeIcon
                  icon={faSave}
                  title="Save trip"
                  data-tooltip-id="save-tooltip"
                  data-tooltip-content={!props.streamDone ? "Wait, trip is generating" : ""}
                />
                {!props.streamDone && <Tooltip id="save-tooltip" style={{ borderRadius: "8px", zIndex: "100000" }} />}
              </div>
              <ExportItinerary data={response} openAuthenticatorModal={props.openAuthenticatorModal} streamDone={props.streamDone} />
              <div className="share-trip" onClick={(event) => openShareModal(event)}>
                <FontAwesomeIcon
                  icon={faShare}
                  title="Share trip"
                  data-tooltip-id="share-tooltip"
                  data-tooltip-content={props.isSaved ? "Share" : "To share, save trip first"}
                />
                <Tooltip id="share-tooltip" style={{ borderRadius: "8px", zIndex: "100000" }} />
              </div>
            </div>
          </div>

          <div className="activities-container">
            {authStatus !== "authenticated" && selectedDay != 0 && (
              <ActivitiesOverlay
                openAuthenticatorModal={props.openAuthenticatorModal}
                isAuthenticatorOpen={props.isAuthenticatorOpen}
                modalRef={props.modalRef}
              />
            )}
            <div style={{ display: selectedDay === 0 ? "block" : "none" }}>
              <Widgets flightDetail={props.flightDetail} destination={props?.destination} />
            </div>

            <div className={authStatus === "authenticated" || selectedDay == 0 ? "activities-cards-container" : "activities-cards-container blurred"}>
              {response?.days?.[selectedDay]?.activities?.map((activity, index, array) => (
                <div key={index}>
                  <div className="d-flex justify-content-between mb-4">
                    <div></div>
                    {index > 0 && activity.address && activity.endTime && activity.startTime && (
                      <>
                        <DistanceComponent currentAddress={activity.address} previousAddress={array[index - 1].address} distanceMode={distanceMode} />
                      </>
                    )}
                    <div className="d-flex gap-3">
                      {props.streamDone && (
                        <>
                          <Dropdown>
                            <Dropdown.Toggle as="div" id="dropdown-custom-components">
                              <FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer", fontSize: "20px" }} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => addActivityWithAi(index)}>Generate with AI</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleAddActivity(index)}>Add Manually</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <FontAwesomeIcon
                            onClick={() => handleAddActivity(index)}
                            icon={faPlus}
                            title="Add Activity"
                            style={{ outline: "none", cursor: "pointer" }}
                            data-tooltip-id="share-tooltip"
                          />
                          <FontAwesomeIcon
                            onClick={() => addActivityWithAi(index)}
                            icon={faPlus}
                            title="Add Activity with AI"
                            style={{ outline: "none", cursor: "pointer" }}
                            data-tooltip-id="share-tooltip"
                          /> */}
                        </>
                      )}
                    </div>
                  </div>
                  {authStatus !== "authenticated" && index === array.length - 1 && selectedDay != 0 && (
                    <ActivitiesOverlay
                      openAuthenticatorModal={props.openAuthenticatorModal}
                      isAuthenticatorOpen={props.isAuthenticatorOpen}
                      modalRef={props.modalRef}
                      streamDone={props.streamDone}
                    />
                  )}
                  <ActivityCard
                    key={index}
                    index={index}
                    fullActivity={activity}
                    activity={activity.activity}
                    address={activity.address}
                    description={activity.description}
                    startTime={activity.startTime}
                    endTime={activity.endTime}
                    destination={props.destination}
                    isEdit={activity?.isEdit}
                    isAdd={activity?.isAdd}
                    blurred={authStatus !== "authenticated" && index === array.length - 1 && selectedDay != 0}
                    setResponse={setResponse}
                    selectedDay={selectedDay}
                    streamDone={props.streamDone}
                    editActivity={editActivity}
                    regenerateActivity={regenerateActivity}
                  />
                  {index === array.length - 1 && selectedDay === response.days.length - 1 && props.streamDone && (
                    <div className="activity-card ">
                      <div className="activity-content">
                        <span className="activity-title">Recommend Ekta Insurance</span>
                        <p>We recommend Ekta Insurance for your trip. Travel safely and stress-free.</p>
                        <a
                          className="buy-ticket-btn activity-btn"
                          href="https://ektatraveling.com/?sub_id=4dfab779213a40e89198f5693-509535"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTicket} title="Share trip" data-tooltip-id="share-tooltip" />
                          Buy Now
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="days-container down">
            {response &&
              response.days &&
              response.days.map((day, index) => (
                <div key={index} className={index === selectedDay ? "day selected" : "day"} onClick={() => handleDayClick(index, true)}>
                  Day {index + 1}
                </div>
              ))}
          </div>
        </div>
        {showSave && (
          <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showSave} onHide={handleCloseSave}>
            <Modal.Header closeButton>
              <Modal.Title>Trip Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="position-relative mt-4">
                <div className="input-labels">Your Trip Name</div>
                <div>
                  <input
                    className="destination-input"
                    placeholder="Like: Rome 5 days"
                    type="text"
                    value={tripName}
                    onChange={(e) => setTripName(e.target.value)}
                  />
                </div>
              </div>
              <p>{confirmationMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseSave}>
                Cancel
              </Button>
              <Button variant="success" onClick={handleSave} disabled={!tripName}>
                Save <FontAwesomeIcon icon={faSave} />
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {showShare && (
          <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showShare} onHide={handleCloseShare}>
            <Modal.Header closeButton>
              <Modal.Title>Share your trip</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="position-relative mt-4">
                <div className="input-labels">Your Trip Link</div>
                <div className="share-input-container">
                  <input
                    className="share-input"
                    placeholder="Like: Rome 5 days"
                    type="text"
                    value={window.location.href}
                    readOnly={true}
                    ref={(input) => setInputShareRef(input)}
                  />
                  <FontAwesomeIcon
                    icon={faClone}
                    onClick={handleCopy}
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={confirmationMessage || "Copy to clipboard"}
                  />
                  <Tooltip id="copy-tooltip" place="right" style={{ borderRadius: "8px", zIndex: "100000" }} />{" "}
                  {/* Initialize the ReactTooltip component */}
                </div>
              </div>
              <ShareButtons url={window.location.href} title={"I just created an itinerary for my next trip using AI, check it out!"} />
            </Modal.Body>
          </Modal>
        )}
      </Col>
      <Col md={6} lg={6} xxl={5} ref={mapRef}>
        {response && response.days && response.days[selectedDay] && response.days[selectedDay].activities && (
          <ItineraryMap
            mainDestination={props.destination}
            activities={(response.days[selectedDay] && response.days[selectedDay].activities) || []}
            streamDone={props.streamDone}
          />
        )}
      </Col>
    </Row>
  );
});

export default Result;
