import React, { useEffect, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomMailchimpForm = ({ user, status, message, onValidated }) => {
  
  const submit = () => {
    if (user && user.attributes && user.attributes.email && user.attributes.name) {
      onValidated({
        MERGE0: user.attributes.email,
        MERGE1: user.attributes.name
      });
    }
  };

  useEffect(() => {
    if (user && user.attributes && user.attributes.email && user.attributes.name) {
      submit();
    }
  }, [user]);

  return (
    <div
      style={{
        display: "none"
      }}
    >
    </div>
  );
};


function MailchimpFormContainer(props) {

    const postUrl = `https://mytripassistant.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomMailchimpForm
                        user={props.user}
                        status={status} 
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpFormContainer;