import React, { useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faWalking, faTrain } from '@fortawesome/free-solid-svg-icons';

const options = [
  { value: 'transit', label: <FontAwesomeIcon icon={faTrain} /> },
  { value: 'walking', label: <FontAwesomeIcon icon={faWalking} /> },
  { value: 'driving', label: <FontAwesomeIcon icon={faCar} /> },
];

function DistanceModeSelector(props) {

  const handleDistanceModeChange = (selectedOption) => {
    props.setDistanceMode(selectedOption.value);
  };

  return (
    <div className='select-mode'>
      <Select
        value={options.find((option) => option.value === props.distanceMode)}
        onChange={handleDistanceModeChange}
        options={options}
        // defaultValue={'driving'}
        isSearchable={false}
        className="react-select-container"
        classNamePrefix="react-select"
        menuPortalTarget={document.body} 
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
}

export default DistanceModeSelector;