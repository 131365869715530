import React, {useEffect, ChangeEventHandler, useState, useRef} from "react";
import '../styles/inputs.css';
import {Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faMapMarkedAlt, faStreetView } from "@fortawesome/free-solid-svg-icons";
import features_1 from "../assets/images/howitworks/features_1.png";
import features_2 from "../assets/images/howitworks/features_2.png";

function HomepageFeatures(props) {


    return (
        <Row id="features">
            <h2>Incoming <span className='text-one'>features</span></h2>
            <Col
                md={12}
                lg={12}
                xxl={6}>
                    <div className="features-card">
                        <img
                            src={features_1}
                            className="d-inline-block align-top"
                        />
                        <div className="text-center">
                            <h4 className="features-title">Travel Hub</h4>
                            <span className="features-txt">Your unique space to connect, share itineraries, and exchange local tips with fellow travelers. Dive into a passionate community, discovering the world through others' eyes and sharing your own journey!</span>
                        </div>
                    </div>
            </Col>
            <Col
                md={12}
                lg={12}
                xxl={6}>
                    <div className="features-card">
                        <img
                            src={features_2}
                            className="d-inline-block align-top"
                        />
                        <div className="text-center">
                            <h4 className="features-title">Group Planning</h4>
                            <span className="features-txt">Utilize real-time collaboration features to propose activities, adjust plans, and confirm details with friends or family. Forget the hassle of endless emails and coordinating schedules over the phone.</span>
                        </div>
                    </div>
            </Col>
        </Row>
    )
}

export default HomepageFeatures;