import React, { useEffect, useState } from "react";
import styled from "styled-components";

import amsterdam from "../assets/images/places/amsterdam.jpg";
import barcelona from "../assets/images/places/barcelona.jpg";
import boston from "../assets/images/places/boston.png";
import bruxelles from "../assets/images/places/bruxelles.jpg";
import florence from "../assets/images/places/florence.png";
import iceland from "../assets/images/places/iceland.jpg";
import lisbon from "../assets/images/places/lisbon.png";
import newyork from "../assets/images/places/newyork.jpg";
import paris from "../assets/images/places/paris.jpg";
import losangeles from "../assets/images/places/losangeles.jpg";
import rome from "../assets/images/places/rome.png";
import tokyo from "../assets/images/places/tokyo.jpg";

const places = [
  { image: amsterdam, text: "Amsterdam" },
  { image: barcelona, text: "Barcelona" },
  { image: boston, text: "Boston" },
  { image: bruxelles, text: "Bruxelles" },
  { image: florence, text: "Florence" },
  { image: iceland, text: "Iceland" },
  { image: lisbon, text: "Lisbon" },
  { image: newyork, text: "New York" },
  { image: paris, text: "Paris" },
  { image: losangeles, text: "Los Angeles" },
  { image: rome, text: "Rome" },
  { image: tokyo, text: "Tokyo" },
];

const PlaceholderWrapper = styled.div`
  position: relative;
  margin-top: 1rem !important;
  width: 100%;
  height: calc(100% - 1rem);
  min-height: 300px;
  @media (max-width: 768px) {
    margin-top: 2rem !important;
    display: none;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 40px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  transition: opacity 1s ease;
`;

const NextPlaceholder = styled(Placeholder)`
  opacity: 0;
`;

const PlaceName = styled.span`
  color: #37ab31;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    display: block;
  }
`;

function ResultPlaceholder() {
  const [placeIndex, setPlaceIndex] = useState(0);
  const [nextPlaceIndex, setNextPlaceIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceIndex(nextPlaceIndex);
      setNextPlaceIndex((prevIndex) => (prevIndex + 1) % places.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [nextPlaceIndex]);

  return (
    <PlaceholderWrapper>
      <Placeholder imageUrl={places[placeIndex].image}>
        <span className="placeholder-text">I am going to <PlaceName>{places[placeIndex].text}</PlaceName></span>
      </Placeholder>
      <NextPlaceholder imageUrl={places[nextPlaceIndex].image}>
        <span className="placeholder-text">I am going to <PlaceName>{places[nextPlaceIndex].text}</PlaceName></span>
      </NextPlaceholder>
    </PlaceholderWrapper>
  );
}

export default ResultPlaceholder;