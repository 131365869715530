import React from 'react'

const AddToLikeInput = (props) => {
    return (
        <div className="position-relative mt-4">
            <div className="input-labels">Is there anything else you would like add to the itinerary?</div>
            <input className="destination-input" value={props.anyThingElse} onChange={(e) => props.setAnyThingElse(e.target.value)} />
        </div>
    )
}

export default AddToLikeInput
