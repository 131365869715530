import React from "react";

function DietaryPreferences(props) {
    const handleCheckboxChange = (preference) => {
        props.setDietaryPreferences((prev) => {
            let updatedPreferences;
            if (prev.includes(preference)) {
                updatedPreferences = prev.filter((item) => item !== preference);
            } else {
                updatedPreferences = [...prev, preference];
            }
            return updatedPreferences;
        });
    };

    return (
        <div className="my-3">
            <p className="m-0 text-start">
                Any dietary preferences:
            </p>
            <div className="d-flex align-items-center my-2 flex-wrap gap-2">
                <span className="pe-3">
                    Vegan
                </span>
                <input
                    type="checkbox"
                    checked={props.dietaryPreferences.includes("vegan")}
                    onChange={() => handleCheckboxChange('vegan')}
                    className={props.dietaryPreferences.includes("vegan") ? "checked" : ""}
                />
                <span className="pe-3">
                    Vegetarian
                </span>
                <input
                    type="checkbox"
                    checked={props.dietaryPreferences.includes("vegetarian")}
                    onChange={() => handleCheckboxChange('vegetarian')}
                    className={props.dietaryPreferences.includes("vegetarian") ? "checked" : ""}
                />
                <span className="pe-3">
                    Gluten-free
                </span>
                <input
                    type="checkbox"
                    checked={props.dietaryPreferences.includes("glutenFree")}
                    onChange={() => handleCheckboxChange('glutenFree')}
                    className={props.dietaryPreferences.includes("glutenFree") ? "checked" : ""}
                />
            </div>
        </div>
    );
}

export default DietaryPreferences;