import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";

function DestinationInput(props) {
  const [inputs, setInputs] = useState([{ placeName: "" }]);
  const location = useLocation();

  // Function to map URL parameter to default placeName
  const getDefaultAddress = (destinationParam) => {
    
    if (destinationParam === "onefamlescorts") {
      return "Onefam Les Corts, Ptge. del Regent Mendieta, 5, Les Corts, 08028 Barcelona, Spagna";
    }
    return decodeURI(destinationParam)
    // // Add more mappings if needed
    // return "";
  };

  // Function to get query parameters from the URL
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  // Use effect to set default placeName from URL
  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const destinationFromUrl = queryParams.get("destination");

    if (destinationFromUrl) {
      const defaultAddress = getDefaultAddress(destinationFromUrl);
      if (defaultAddress) {
        setInputs([{ placeName: defaultAddress }]);
        props.setDestination([{ address: defaultAddress }]);
      }
    }
  }, [location.search]);

  const handleSelect = async (address, index) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const newDestination = {
        address: address,
        latLng: latLng,
      };

      const updatedInputs = [...inputs];
      updatedInputs[index].placeName = address;
      setInputs(updatedInputs);

      const updatedDestinations = [...props.destination];
      updatedDestinations[index] = newDestination;
      props.setDestination(updatedDestinations);

      if (index !== 0 && index === inputs.length - 1) {
        setInputs([...inputs, { placeName: "" }]);
      }
    } catch (error) {
      console.error("Error selecting place:", error);
    }
  };

  const addField = () => {
    setInputs([...inputs, { placeName: "" }]);
  };

  const removeDestination = (index) => {
    const newDestinations = props.destination.filter((_, i) => i !== index);
    props.setDestination(newDestinations);

    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div className="position-relative my-3" key={index}>
          <div className="input-labels">{index === 0 ? "I am going to" : "And"}</div>
          <PlacesAutocomplete
            value={input.placeName}
            onChange={(address) => {
              const updatedInputs = [...inputs];
              updatedInputs[index].placeName = address;
              setInputs(updatedInputs);
            }}
            onSelect={(address) => handleSelect(address, index)}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <div style={{ position: 'relative' }}>
                  <input
                    {...getInputProps({
                      placeholder: index === 0 ? "Your Destination" : "Add another Destination",
                      className: "destination-input",
                    })}
                  />
                  <span
                    onClick={() =>
                      inputs.length < 2 ? addField() : removeDestination(index)
                    }
                  >
                    <FontAwesomeIcon
                      className="destination-icon"
                      style={{
                        cursor: "pointer",
                        color: "gray",
                        position: 'absolute',
                        right: '15px',
                        top: '50%',
                        zIndex: 10,
                        transform: 'translateY(-50%)'
                      }}
                      icon={inputs.length < 2 ? faPlus : faXmark}
                    />
                  </span>
                </div>
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="suggestion-item">Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                    return (
                      <div key={i} {...getSuggestionItemProps(suggestion, { className })}>
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      ))}
    </div>
  );
}

export default DestinationInput;
