import React, { useState, useRef, useEffect } from "react";
import "../../styles/override_datepicker.css";
import FocusTrap from "focus-trap-react";
import { usePopper } from "react-popper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function TimePickerActivities({ time, setTime, isEdit }) {
    console.log("🚀 ~ TimePickerActivities ~ time:", time)
    const [isStartTimePopperOpen, setIsStartTimePopperOpen] = useState(false);
    const startTimePopperRef = useRef(null);
    const startTimeButtonRef = useRef(null);
    const [startTimePopperElement, setStartTimePopperElement] = useState(null);
    const [times, setTimes] = useState({})

    const convertTimeStringToDate = (timeString) => {
      const [hours, minutes] = timeString.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds
      return date;
    };

    useEffect(() => {
      if(isEdit && time.startTime && time.endTime) {
        setTimes({
          startTime: convertTimeStringToDate(time?.startTime),
          endTime: convertTimeStringToDate(time?.endTime),
        })
      } 
      if(!isEdit && !time?.startTime) {
        setTimes({})
      }

    },[time,isEdit])
    

    const startTimePopper = usePopper(startTimePopperRef.current, startTimePopperElement, {
        placement: "right",
    });

    const closeStartTimePopper = () => {
        setIsStartTimePopperOpen(false);
        startTimeButtonRef?.current?.focus();
    };

    const handleStartTimeButtonClick = () => {
        setIsStartTimePopperOpen(!isStartTimePopperOpen);
    };

    const handleStartTime = (time) => {
        setTime((prev) => ({...prev , startTime: format(time,  'HH:mm') , endTime : ""}))
        setTimes({startTime: time  })
        setIsStartTimePopperOpen(false)
    }
    const handleEndTime = (time) => {
     setTimes((prev) => ({
            ...prev,
            endTime: time 
        }))
        setTime((prev) => ({
            ...prev,
            endTime: format(time,  'HH:mm')
        }))
    }

      // Handle time conversion for display
  const formatTime = (time) => (time ? format(time, 'HH:mm') : "");

    return (
        <div className="position-relative w-100 mt-1 d-flex gap-1 flex-grow-1">
          <div className="flex-grow-1">
            <input
              placeholder="Start Time"
              value={formatTime(times.startTime)}
              readOnly
              onClick={handleStartTimeButtonClick}
              className="trip-add-input w-100"
            />
          </div>
          <div className="flex-grow-1">
            <DatePicker
              selected={times.endTime}
              onChange={handleEndTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              placeholderText="Select end time"
              minTime={times.startTime ? new Date(times.startTime.getTime()).setMinutes(times.startTime.getMinutes() + 15) : null}
              maxTime={times.startTime ? new Date(times.startTime.getTime()).setHours(23, 59) : null}
              // minTime={times.startTime ? times.startTime : null}
              // maxTime={times.startTime ? new Date().setHours(23, 59) : null}
              className="trip-add-input w-100"
            />
          </div>
          {isStartTimePopperOpen && (
            <FocusTrap
              active
              focusTrapOptions={{
                initialFocus: false,
                allowOutsideClick: true,
                clickOutsideDeactivates: true,
                onDeactivate: closeStartTimePopper,
                fallbackFocus: startTimeButtonRef.current,
              }}
            >
              <div
                tabIndex={-1}
                style={{ ...startTimePopper.styles.popper}}
                className="trip-add-input"
                {...startTimePopper.attributes.popper}
                ref={setStartTimePopperElement}
                role="dialog"
                aria-label="TimePicker start time"
              >
                <DatePicker
                  selected={times.startTime}
                  onChange={handleStartTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  placeholderText="Select start time"
                  inline
                  className="w-100"
                />
              </div>
            </FocusTrap>
          )}
        </div>
      );
      
}

export default TimePickerActivities;
